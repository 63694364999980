<template>
  <grid
    item-min-width="300px"
    class="align-center account"
  >
    <v-select
      class="ancestors"
      v-model="computedAncestor"
      :items="ancestors"
      item-text="text"
      item-value="id"
      :label="$t('t.Ancestors')"
      :hide-details="true"
    />
    <column-picker
      :selected-col-id.sync="computedCol"
      :filters="filters"
      :label="$t(`t.Selector`)"
      :attach="false"
      :show-icon="true"
      currency-disabled
      clearable
      :dense="false"
    />
  </grid>

</template>

<script>
export default {
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    Grid: () => import('@/components/grid')
    // Document: () => import('./document')
  },
  computed: {
    computedCol: {
      get () { return this.dataCol },
      set (v) {
        this.dataCol = v
        this.dataDocumentEmit()
      }
    },
    computedAncestor: {
      get () { return this.dataAncestor },
      set (v) {
        this.dataAncestor = v
        this.dataDocumentEmit()
      }
    },
    filters () {
      return { 'document-types': [[`${this.targetDocTy}`], []] }
    }
  },
  data () {
    return {
      ancestors: [
        { id: 'root', text: this.$t('t.RootItem') },
        { id: 'parent', text: this.$t('t.ParentItem') },
        { id: 'current', text: this.$t('t.CurrentItem') }
      ],
      dataCol: null,
      dataAncestor: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = { ancestor: this.dataAncestor, col: this.dataCol }

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    targetDocTy: String
  },
  watch: {
    document: {
      handler (v) {
        const a = this.lodash.cloneDeep(v)
        this.dataCol = a?.col
        this.dataAncestor = a?.ancestor

        if (!a || !this.ancestors.some(a => a.id === this.dataAncestor)) {
          this.dataAncestor = 'current'
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="stylus" scoped></style>
